import * as R from 'ramda'
import {graphql} from 'gatsby'
import {locationPropTypes, pageContextPropTypes} from 'helpers/propTypes'
import {shape} from 'prop-types'
import React, {useEffect, useState} from 'react'

import Decoration from 'components/UI/Decoration'
import Decorations from 'components/UI/Decorations'
import PartnerContent from 'components/TechnologyPartner/PartnerContent'
import Partners from 'components/PartnersAndTech/Partners'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import TechnologyPartnersHeader from 'components/PartnersAndTech/Header'
import useIsBrowser from 'hooks/useIsBrowser'

const PartnersAndTech = ({pageContext, data, location}) => {
  const [selectedPartner, setSelectedPartner] = useState(null)
  const partners = R.pathOr(null, ['countryPartnersData', 'partners'], data)
  const formData = R.pathOr(null, ['contentfulForm'], data)
  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)
  const countryData = R.path(['contentfulCountry'], data)

  const {
    pageTitle,
    metaTitle,
    shortDescription,
    metaDescription,
    eulaCallToAction,
    insightsSectionDefaultLabel,
    playbookSectionDefaultLabel,
    discoverMoreCtaDefaultLabel,
    discoverMoreSectionDefaultLabel,
    whyKeyrusSection,
    demoFormTitle,
  } = R.pathOr(null, ['allContentfulPartnersAndTech', 'nodes', 0], data)

  const defaultLabels = {
    insightsSectionDefaultLabel,
    playbookSectionDefaultLabel,
    discoverMoreCtaDefaultLabel,
    discoverMoreSectionDefaultLabel,
  }

  const insights = R.pathOr(
    null,
    ['allContentfulInsight', 'nodes'],
    data,
  ).filter(insight => {
    if (!selectedPartner) {
      return true
    }
    if (!insight.partners) {
      return false
    }

    return insight.partners.find(
      partner => partner.contentful_id === selectedPartner.contentful_id,
    )
  })

  const keyplays = R.pathOr(
    null,
    ['allContentfulKeyPlay', 'nodes'],
    data,
  ).filter(keyplay => {
    if (!selectedPartner) {
      return true
    }
    if (!keyplay.partners) {
      return false
    }

    return keyplay.partners.find(
      partner => partner.contentful_id === selectedPartner.contentful_id,
    )
  })

  const pageMetaData = {
    metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  useEffect(() => {
    if (useIsBrowser && !window.location.hash.includes('#/')) {
      setSelectedPartner(null)
    } else {
      // eslint-disable-next-line prefer-destructuring
      const hashValue = window.location.hash.replace('#/', '')
      const [selectedPartnerName] = hashValue.split('/')
      const selectedPartnerObject = partners.find(
        partner => partner.name === decodeURI(selectedPartnerName),
      )

      if (!selectedPartnerObject) {
        window.location.hash = ''
      }

      setSelectedPartner(selectedPartnerObject)
    }
  })

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      {selectedPartner && (
        <PartnerContent
          partner={selectedPartner}
          insights={insights}
          keyplays={keyplays}
          countryData={countryData}
          formData={formData}
          location={location}
          siteURL={siteURL}
          defaultLabels={defaultLabels}
          whyKeyrusSection={whyKeyrusSection}
          demoFormTitle={demoFormTitle.replace(' ?', '\u00A0?')}
        />
      )}
      {!selectedPartner && (
        <>
          <TechnologyPartnersHeader
            pageTitle={pageTitle}
            description={shortDescription.shortDescription}
            eulaCallToAction={eulaCallToAction}
          />
          <Decorations>
            <Decoration color="orange" right={0} top={130} />
            <Decoration color="blue" left={0} top={154} />
          </Decorations>
          <Section hasPaddingTop={false}>
            <Partners
              partners={partners}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
              hasPartnersName
            />
          </Section>
        </>
      )}
    </>
  )
}

PartnersAndTech.propTypes = {
  data: shape({}).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

export const pageQuery = graphql`
  query templatePartnersAndTech(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulPartnersAndTech(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      nodes {
        pageTitle
        metaTitle
        shortDescription {
          shortDescription
        }
        metaDescription {
          metaDescription
        }
        eulaCallToAction
        insightsSectionDefaultLabel
        playbookSectionDefaultLabel
        whyKeyrusSection {
          title
          description {
            raw
          }
          variation
          showContentSection
        }
        discoverMoreSectionDefaultLabel
        discoverMoreCtaDefaultLabel
        demoFormTitle
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      id
      name
      ...countryDataFields
      salesforce
    }
    countryPartnersData: contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      ...partnerFields
    }
    allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
      }
      sort: {publicationDate: DESC}
    ) {
      nodes {
        contentful_id
        id
        title
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        partners {
          contentful_id
        }
        publicationDate(formatString: "DD.MM.YY")
        type {
          name
        }
        industries {
          contentful_id
          name {
            name
          }
        }
        departments {
          contentful_id
          name {
            name
          }
        }
        services2 {
          contentful_id
          name
        }
        slug
      }
    }
    allContentfulKeyPlay(
      filter: {
        node_locale: {eq: $nodeLocale}
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
      }
    ) {
      nodes {
        ...playbookKeyPlayFields
      }
    }
    contentfulForm(name: {eq: "Lead form"}, node_locale: {eq: $nodeLocale}) {
      ...FormFields
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
  }
`

export default PartnersAndTech
